<!-- 评价 -->
<template>
	<div :class="themeClass" class="min-width1100 margin-t-20">
		<div class="flex-row margin-b-5">
			您的位置：
			<breadcrumb></breadcrumb>
		</div>
		<div class="flex-colum border-F2F2F2">
			<div class="evaluate-title font-size16 font-weight700 padding-lr-15">
				填写评价
			</div>
			<div class="order-top bg-F2F4FD padding-lr-15">
				<span class="margin-r-30">{{orderDetails.fBeginDate | formatDate}}</span>
				<span>订单号：<span class="color-theme">{{ orderDetails.fOrderNumber }}</span></span>
			</div>
			<div class="order-bottom padding-lr-15 padding-tb-25 flex-row-space-between-center border-bottom-F2F2F2 margin-b-10">
				<div class="flex-row">
					<div class="img-box radius10 flex-row-center-center margin-r-10">
						<el-image class="imageRadius" :src="orderDetails.imageUrl"></el-image>
					</div>
					<div class="flex-colum">
						<div class="margin-r-20 margin-b-5">{{ orderDetails.fGoodsFullName }}</div>
						<div class="flex-row font-size12 margin-b-5">
							<!-- <div class="distributionMode-box linear-gradient padding-lr-5 font-color-fff margin-r-10">企业专项</div> -->
							<div v-if="orderDetails.fTradingModelID=='2'" class="label font-color-fff text-center bg-40C6A9 margin-r-5">企业购</div>
							<div v-if="orderDetails.fTradingModelID==3" class="label-bg font-color-fff text-center bg-40C6A9 margin-r-5">
								{{orderDetails.fTradingModel}}
							</div>
							<div v-if="orderDetails.fShopTypeID==1" class="label-bg font-color-fff text-center bg-40C6A9 margin-r-5">
								{{orderDetails.fShopTypeName}}
							</div>
							<div class="modeOfDelivery-box border-theme color-theme padding-lr-5">{{ orderDetails.fDelivery }}</div>
						</div>
						<div class="font-color-ae">工程：{{ orderDetails.fProjectName == "" ? "无" : orderDetails.fProjectName }}</div>
					</div>
				</div>
				<div class="flex-row bottom-right">
					<div class="padding-lr-37 border-lr-F2F2F2">X{{ orderDetails.fPlanAmount }}{{ orderDetails.fUnitName  }}</div>
					<div class="padding-lr-37 font-size13 font-weight700" v-if="orderDetails.fTradingModelID != '3' || orderDetails.fTradingModelID != '5'">￥{{ orderDetails.fGatheringMoney }}</div>
					<div class="padding-lr-37 font-size13 font-weight700" v-else>{{ orderDetails.fUsePoint + ' 积分' }}</div>
				</div>
			</div>
			<!-- TODO 评价获得积分  暂时没有这块的设计 -->
			<!-- <div class="evaluate-box flex-row bg-FFF7EE">
				<div class="evaluate-tag margin-rl-10 bg-FF9900 font-color-fff margin-tb-auto padding-lr-10 font-size12 font-weight700">
					评价
				</div>
				<div>
					本月完成优质评价，享<span class="color-theme margin-rl-5">2</span>倍积分
				</div>
			</div> -->
			<div class="rate-box padding-15 bg-F2F4FD">
				<div v-for="(item,index) in enableList" class="flex-colum line-height24 margin-b-10">
					<div class="flex-row-align-center margin-b-5">
						<div class="enable-title margin-r-20">{{ item.fEvaluationItemName }}</div>
						<el-rate @change="change(item.fLevel,index)" v-model="item.fLevel" :max="item.fLevelData" :colors="['#FF3300','#FF3300','#FF3300']"
						 :disabled-void-color="'#e4e4e4'"></el-rate>
					</div>
					<div class="flex-row-wrap" style="width: 1068px;" v-if="item.fLevel<=3 && item.checked">
						<div class="evluationList-box padding-lr-10 radius20 margin-r-10 font-color-999 font-size12 pointer margin-b-5"
						 :class="item1.checked?'border-theme':''" v-for="(item1,index1) of item.fEvluationList" @click="evluationListClick(index,item1.title)">
							{{item1.title}}
						</div>
					</div>
				</div>
			</div>
			<!-- <div class="padding-lr-15 margin-tb-20">
				<el-checkbox v-model="checked">匿名评价</el-checkbox>
			</div> -->
			<div class="padding-lr-15 margin-b-15 margin-t-10">
				<div class="evaluate-btn text-center background-color-theme pointer" @click="submitClick">
					提交评价
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		mapGetters
	} from 'vuex';
	import breadcrumb from '@/components/breadcrumb.vue'
	export default {
		components: {
			breadcrumb
		},
		data() {
			return {
				checked: false,
				// 订单信息
				orderDetails: {},
				// 订单ID
				paramOrderID: '',
				//商品id
				fGoodsID: '',
				// 评价项目类别
				enableList: []
			}
		},
		computed: {
			...mapGetters(['getThemeName']),
			themeClass() {
				return `theme-${this.getThemeName}`;
			}
		},
		async mounted() {
			this.paramOrderID = await this.$store.getters.getCommonData.id;
			this.fGoodsID = await this.$store.getters.getCommonData.goodsID;
			console.log('this.fGoodsID', this.fGoodsID);
			this.getOrderDetails()
			this.getEnable()
		},
		methods: {
			// 获取订单信息
			getOrderDetails() {
				this.ApiRequestPostNOMess("api/mall/ebsale/order/get", {
					id: this.paramOrderID
				}).then(res => {
					this.orderDetails = res.obj;
					this.getOrderPicture(res.obj.fGoodsID);
				}).catch(err => {

				})
			},
			//获取商品图片
			getOrderPicture(fGoodsID) {
				this.ApiRequestPostNOMess('api/mall/goods/goods/get', {
					id: fGoodsID
				}).then(
					res => {
						this.orderDetails.imageUrl = res.obj.fPicturePath;
						this.$forceUpdate(); //解决动态该数据之后渲染数据慢
					},
					error => {}
				);
			},
			// 获取评价项目
			getEnable() {
				this.ApiRequestPostNOMess("api/mall/ebbase/order-evaluation-item/get-list-from-customer", {
					maxResultCount: 10,
					filter: "",
					skipCount: 0
				}).then(res => {
					res.obj.forEach(item => {
						item.fLevel = 0;
						item.checked = false;
						item.fEvluationList = [];
						item.fEvluation = '';
						item.fEvluationItem.forEach((childitem, childindex) => {
							let data = {
								title: childitem,
								checked: false
							}
							item.fEvluationList.push(data)
						})
					})
					this.enableList = res.obj;
				}).catch(err => {

				})
			},
			// 评分变化
			change(val, index) {
				console.log("评分变化", val);
				if (val <= 3) {
					this.enableList[index].checked = true;
				} else {
					this.enableList[index].checked = false;
				}
			},
			//评分少于等于三星点击下面文字事件
			evluationListClick(index, val) {
				this.enableList[index].fEvluationList.forEach((childitem, childindex) => {
					if (childitem.title == val) {
						childitem.checked = !childitem.checked;
					}
				})
			},
			// 提交评价事件
			submitClick() {
				let haveNoEnable = false;
				let haveNoEnable1 = false;
				let noEnableItem = "";
				for (let item of this.enableList) {
					if (item.fLevel == 0) {
						haveNoEnable = true;
						noEnableItem = noEnableItem + item.fEvaluationItemName + ","
					}
					if (item.fLevel <= 3) {
						let findData = item.fEvluationList.find(findItem => findItem.checked)
						console.log('findData', findData);
						if (findData) {

						} else {
							haveNoEnable1 = true;
						}
					}
				}
				if (haveNoEnable == true) {
					noEnableItem = noEnableItem.slice(0, noEnableItem.length - 1)
					this.$message({
						type: 'info',
						message: '请对' + noEnableItem + "进行评价"
					});
					return;
				}
				if (haveNoEnable1 == true) {
					this.$message({
						type: 'info',
						message: '请选择评价内容'
					});
					return;
				}
				// fLevel:等级 几颗星，最多几颗星可以从 项目中获取
				// fOrderID:订单ID
				// fEvaluationItemID:项目ID

				let params = {
					fOrderID: this.paramOrderID,
					items: []
				};
				this.enableList.forEach((item) => {
					item.fEvluation = [];
					item.fEvluationList.forEach((childitem, childindex) => {
						if (childitem.checked) {
							item.fEvluation.push(childitem.title);
						}
					})
					let data = {
						fLevel: item.fLevel,
						fEvaluationItemID: item.fEvaluationItemID,
						fEvaluationText: item.fEvluation
					}
					params.items.push(data);
				})
				console.log('params', params);
				this.ApiRequestPostNOMess("api/mall/ebsale/order/evaluation/create", params).then(res => {
					this.$message({
						type: 'success',
						message: '评价成功'
					});
					this.$router.go(-1);
				}).catch(err => {

				})
			}
		}
	}
</script>

<style lang="scss" scoped>
	.color-theme {
		@include themify($themes) {
			color: themed('themes_color');
		}
	}

	.background-color-theme {
		@include themify($themes) {
			background-color: themed('themes_color');
			color: themed('text_color_white');
		}
	}

	.border-theme {
		@include themify($themes) {
			color: themed('themes_color');
			border: 1px solid themed('themes_color');
		}
	}

	.enable-title {
		width: 60px;
	}

	.label {
		height: 16px;
		line-height: 16px;
		padding: 1px 5px;
	}

	.label-bg {
		// width: 56px;
		background: linear-gradient(to right, #ff0000, #ff9999);
		padding: 1px 5px;
	}

	.bg-40C6A9 {
		background-color: #40C6A9;
	}


	.imageRadius {
		width: 65px;
		height: 65px;
		border-radius: 10px;
	}

	.evaluate-title {
		height: 44px;
		line-height: 44px;
	}

	.order-top {
		height: 35px;
		line-height: 35px;
	}

	.order-bottom {}

	.img-box {
		width: 65px;
		height: 65px;
		box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.16);
	}

	.img-style {
		width: 44px;
		height: 59px;
	}

	.distributionMode-box {
		height: 16px;
		line-height: 16px;
	}

	.modeOfDelivery-box {
		height: 16px;
		line-height: 16px;
	}

	.linear-gradient {
		background-image: linear-gradient(to right, #FF0000, #FF9999);
	}

	.padding-lr-37 {
		padding-left: 37px;
		padding-right: 37px;
	}

	.bottom-right {
		height: 65px;
		// line-height: 65px;
	}

	.evaluate-box {
		height: 37px;
		line-height: 37px;
	}

	.evaluate-tag {
		height: 20px;
		line-height: 20px;
		border-top-left-radius: 10px;
		border-bottom-right-radius: 10px;
	}

	.line-height24 {
		line-height: 24px;
	}

	.evluationList-box {
		height: 22px;
		line-height: 22px;
		border: 1px solid #999999;
	}

	.evaluate-btn {
		width: 235px;
		height: 45px;
		line-height: 45px;
	}
</style>
